<template>
  <div>
    <b-card class="mb-0">
      <b-overlay
        :show="loading"
        rounded
        variant="transparent"
        opacity="0.33"
        blur="2px">
        <b-table
          class="position-relative"
          :items="items"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No se encontraron roles disponibles">
          
          <template #cell(permissions)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.permissions.map(e => e.label).join(', ') }}</span>
            </div>
          </template>

          <template #cell(created_at)="data">
            <div class="text-nowrap">
              <span class="align-text-top text-capitalize">{{ data.item.created_at | formatDateTimeHumane }}</span>
            </div>
          </template>

          <!-- Column: Actions -->
          <!-- <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL">
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"/>
              </template>
              <b-dropdown-item @click="permissions(data)">
                <feather-icon icon="LockIcon" />
                <span class="align-middle ml-50">Agregar Permiso</span>
              </b-dropdown-item>
            </b-dropdown>
          </template> -->
        </b-table>
      </b-overlay>
    </b-card>

    <b-modal
      ref="modalPermissions"
        v-model="showModal"
        title="Permisos"
        :ok-title="'Guardar'"
        cancel-title="Cancelar"
        centered
        no-close-on-backdrop
        @ok="saveMetho">
      <b-overlay
          :show="loading"
          rounded
          responsive
          variant="transparent"
          opacity="0.33"
          blur="2px">
          <b-row>
            <b-col class="mb-2">
              <b-form @submit.stop.prevent="saveMetho">
                <v-select
                    v-model="selectPermision"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="permissionsOptions"
                    label="label"
                    :reduce="(item) => item.id"
                    multiple/>
              </b-form>
            </b-col>
          </b-row>
        </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay, BModal, BCardText,BForm,VBModal
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BModal,
    BCardText,
    vSelect,
    BForm,
    VBModal
  },
  directives: {
      'b-modal': VBModal,
      Ripple,
  },
  data(){
    return {
      items: [],
      showModal: false,
      tableColumns: [
        { key: 'label', label:'Nombre', sortable: true },
        // { key: 'permissions', label:'Permisos', sortable: true },
        { key: 'created_at', label:'Creado', sortable: true },
        // { key: 'actions', label:'Acciones' },
      ],
      selectPermision: [],
      loading: false,
      row: {},
      permissionsOptions:[],
    }
  },
  watch:{
    showModal(val){
      if(!val){
          this.selectPermision = []
      }
    }
  },
  mounted() {
    this.fetchRoles()
  },
  methods: {
    show() {
        this.showModal = true
    },
    hide() {
        this.showModal = false
    },
    async saveMetho(bvModalEvt) {
        try {
            this.loading = true
            bvModalEvt.preventDefault()
            
            if(this.modeEdit){
                await this.$store.dispatch('roles/unsyncRoles')
            }else{
                await this.$store.dispatch('roles/syncRoles', {roleId: this.row.id, permissions: this.selectPermision})
            }
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Éxito',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                    text: `Permisos sincronizados correctamente`,	
                },
            })
            this.showModal = false
            this.fetchRoles()
        } catch (error) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'Error',
                    icon: 'AlertCircleIcon',
                    variant: 'danger',
                    text: 'Ha ocurrido un error en el servidor',
                },
            })
        }finally{
            this.loading = false
        }
    },
    async fetchRoles() {
      try {
        this.loading = true
        this.items = await this.$store.dispatch('roles/index', {params: {permissions: false}})
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async permissions(data) {
      this.row = data.item
      this.selectPermision = data.item.permissions
      try {
        this.loading = true
        this.permissionsOptions = await this.$store.dispatch('roles/index', {params: {permissions: true}})
      } catch (error) {
        console.log(error, 'roles@permissions')
      } finally {
        this.loading = false
      }
      this.$refs.modalPermissions.show()
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
